.bloc_tabs {
    display: flex;
    list-style: none;
}

.bloc_tabs li {
    font-size: 16px;
    letter-spacing: .5px;
}

.tabs {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
    outline: none;
    background: transparent;
}

.active_tabs {
    color: #36b9cc;
    border-bottom: 3px solid;
}

.content_tabs {
    flex-grow: 1;
}

.contentShow {
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
}

.active_content {
    display: block;
}