.taxlistTemplateDesc * {
    padding: 0;
    /* margin: 0 !important */
}

.taxlistTemplatemain {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* height: 150px; */
    /* background-color: rgb(156, 99, 99); */
    margin: 14px 0px;
    display: none;
    padding: 14px;
    border-radius: 15px;
    border: 1px solid black;
    /* background: url("../../icons/rectangle.png"); */
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
}

.taxlistTemplateImage span {
    /* border: 1px solid red; */
    /* display: inline-block; */
    /* border: 1px solid red; */
    padding: 6px;
    border-radius: 50%;
    width: 35px;
    /* background: blue; */
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 500;

}



.taxlistTemplateDesc {
    width: 100%;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 5px;
    /* border: 1px solid rgb(4, 245, 104); */
}

.taxlistTemplateDesc div:nth-child(1) p:nth-child(2) {
    font-weight: 700;
    color: black;
}

.taxlistTemplateDesc div:nth-child(3) p {
    width: 100%;
    font-size: 16px;
}

.taxlistTemplateDesc div:nth-child(3) p span:nth-child(2) {
    color: black;
    font-weight: 700;
    /* font-size: 15px; */
}



.taxlistTemplateDesc>div>p span {
    /* color: #001AD5; */
    font-weight: 500;
    font-size: 16px;

}

.taxlistTemplateDesc p {
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    text-transform: capitalize;

}

.taxlistTemplateedit {
    position: absolute;
    right: 10px;
    top: 10px;
    /* background-color: yellow; */
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.success {
    background-color: blue!important ;
    color: white;
    border: none;
    outline: none;
    margin-top: 7px !important;
    padding: 3px 6px;
    font-size: 14px;
    font-weight: bold;
    width: 83px;
    border-radius: 5px;
}

.blue {
    background-color: #105293 !important;
    color: white;
    border: none;
    outline: none;
    /* margin-top: 7px !important; */
    padding: 3px 6px;
    font-size: 14px;
    font-weight: bold;
    width: 130px;
}

.danger {
    background-color: red !important;
    border: none;
    outline: none;
    margin-top: 7px !important;
    padding: 3px 6px;
    font-size: 14px;
    font-weight: bold;
    width: 83px;
}

.categoryWidth {
    width: 27%;
}

@media screen and (max-width: 786px) {

    .categoryWidth {
        width: 100%;
    }

    .taxlistTemplatemain {
        display: block;
        padding: 20px;
    }

    .taxlistTemplateDesc p {
        display: inline-block;
    }

    .taxlistTemplateedit {
        top: 20px;
    }
}

@media screen and (max-width: 375px) {

    .taxlistTemplateDesc {
        gap: 5px;
    }


    .taxlistTemplateImage img {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }

    .taxlistTemplateDesc p:nth-child(3) {
        padding-bottom: 0px
    }


}

@media screen and (max-width: 375px) {
    .taxlistTemplatemain {
        padding: 7px;
    }
}

@media screen and (max-width: 320px) {
    .taxlistTemplateDesc div:nth-child(3) p {

        font-size: 11px;
    }

    .taxlistTemplateDesc div:nth-child(1) {
        font-size: 14px;
    }

    .taxlistTemplateDesc div:nth-child(2) p {
        font-size: 14px;
    }

    .taxlistTemplateedit {
        top: 4px;
        right: 4px;
    }

    .taxlistTemplateImage span {
        padding: 3px;
        width: 28px;


    }


}