.sellerListing_mobile {
  display: none;
}

.sellerListOptions {
  /* border: 1px solid red; */
  width: 46%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b9bbc0;
  padding-left: 5px;
  font-weight: 300;
  color: black;
  border-radius: 5px;
  margin-top: 10px;
}

.sellerListOptions select {
  background-color: blue;
  width: 140px;
  margin: 0 !important;
  font-weight: 500;
  color: white;
}

.sellerListOptions select option {
  background-color: #f8f9fc;
  color: black;
}

@media screen and (max-width: 768px) {

  .sellerListOptions {
    width: 84%;
    font-size: 12px;
  }
  .sellerListing_desktop {
    display: none;
  }

  .sellerListing_mobile {
    display: block;
    background-color: white;
  }

  .cardbody {
    background-color: white;
    padding: 15px !important;
  }


}

@media screen and (max-width: 375px) {
  .sellerListOptions {
    /* width: 46%; */
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .cardbody {
    padding: 6px !important;
  }

  .sellerListOptions {
    width: 100%;
    font-size: 11px;
  }

  .sellerListOptions select {
    width: 150px;
  }
} 