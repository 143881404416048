.inputMain img {
    display: none;
}

@media screen and (max-width: 768px) {
    .inputMain {
        padding: 3px 5px;
        border-radius: 10px;
        width: 48%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        /* background-color: #0094ff; */
        background-color: inherit;
        border: 1px solid black;
        margin: 2px;

    }

    .inputTypeDate {
        border: 1px solid #aaa;
        border-radius: 3px;
        padding: 5px;
        background-color: transparent;
        margin-left: 3px;
        border: none;
        /* width: 29px; */
        font-size: 13px;
       
        background: blue;
    }

    .selector {
        /* margin-top: 5px; */
        /* border: 1px solid black; */
        outline: none;
        font-size: 13px;
        width: 48%;
        margin-bottom: 0px !important;
    }

    .date{
      color: black;
      font-size: 13px;
    }

    .inputMain input {
        background-color: inherit;
        border: none;
        outline: none;
        width: 90%;
        /* color: white; */
        color: black;
        margin-bottom: 0px !important;
        padding: 0px !important;
    }

    .inputMain img {
        width: 11%;
        display: block;
    }

    .inputMain input::placeholder {
        /* color: white; */
        color: black;
        font-size: 11px;
    }

}

  .inputMain img {
      display: none;
  }

  @media screen and (max-width: 768px) {
      .inputMain {
          padding: 3px 5px;
          border-radius: 10px;
          width: 48%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          /* background-color: #0094ff; */
          background-color: inherit;
          border: 1px solid black;
          margin: 2px;

      }

      .inputTypeDate {
          border: 1px solid #aaa;
          border-radius: 3px;
          padding: 5px;
          background-color: transparent;
          margin-left: 3px;
          border: none;
          /* width: 29px; */
          font-size: 13px;
         
          background: blue;
      }

      .selector {
          /* margin-top: 5px; */
          /* border: 1px solid black; */
          outline: none;
          font-size: 12px;
          width: 48%;
          margin-bottom: 0px !important;
      }

      .date{
        color: black;
        font-size: 13px;
      }

      .inputMain input {
          background-color: inherit;
          border: none;
          outline: none;
          width: 90%;
          /* color: white; */
          color: black;
          margin-bottom: 0px !important;
          padding: 0px !important;
      }

      .inputMain img {
          width: 10%;
          display: block;
      }

      .inputMain input::placeholder {
          /* color: white; */
          color: black;
          font-size: 11px;
      }

  }

  @media screen and (max-width: 321px){
    .selector{
        font-size: 10px;
    }
  }
