.search_input_container {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search_select,
.search_input {
  border: none !important;
}

.productContainer {
  display: flex;
  gap: 25px;
}

.productContainer .leftpart {
  width: 65%;
}

.productContainer .rightpart {
  width: 33%;
}

.whiteContainer {
  border: 1px solid rgb(209, 207, 207);
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
}

.whiteContainer input::placeholder,
.whiteContainer textarea::placeholder {
  color: transparent;
  /* Hides the placeholder text by making it transparent */
}

.whiteContainer h3 {
  font-size: 22px;
  color: black;
  font-weight: 600;
  margin-bottom: 16px;
}

.whiteContainer input,
.whiteContainer select,
.whiteContainer textarea {
  background-color: #F8FAFF;
  color: rgb(53, 52, 52);
}

.imageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  max-height: 600px;
  overflow: scroll;
  color: black;
  background: white;
  width: 47%;
  padding: 0px 10px;

}

.imageContainer::-webkit-scrollbar {
  display: none;
}

.productsearchInput {
  width: 75%;
}

.buttonsContainers {
  display: inline;
}

.editContainer {
  display: flex;
  gap: 10px;
}

.editContainerMobile {
  display: none;
}

.productDeleteIcon {
  color: "red";
  cursor: "pointer";
  font-size: "30px"
}

.productEditIcon {
  height: 32px;
  width: 32px;
}

.productQRIcon {
  cursor: "pointer";
  font-size: "30px"
}

@media screen and (max-width: 500px) {

  .imageContainer {
    width: 90%;
  }

  .productContainer {
    display: block;
  }

  .productContainer .leftpart {
    width: 100%;
  }

  .productContainer .rightpart {
    width: 100%;
  }

  .whiteContainer {
    margin-top: 15px;
  }

  .productsearchInput {
    width: 100%;
  }

  .buttonsContainers {
    display: block;
    margin-top: 10px;
  }

  .editContainer {
    display: none;
  }

  .productDeleteIcon {
    color: red;
    font-size: 25px;
  }

  .productEditIcon {
    height: 24px;
    width: 24px;
  }

  .productQRIcon {
    cursor: "pointer";
    font-size: "10px"
  }

  .editContainerMobile {
    display: block;
  }
}