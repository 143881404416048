.inv_con{
    /* style={{border: "2px solid black", maxWidth:"1024px", margin: "0 auto"}}  */
    /* border: 1px solid black; */
    max-width: 1024px;
    margin: 0 auto;
    padding: 10px;

}

.inv_con p, h6{
    margin: 0 !important;
    padding: 0 !important;
}

.inv_con .text_boxes{
    width: 33%;
    /* border: 1px solid red; */
}

.invoiceTableHead th{
    background-color: grey !important;
  
}

.invoiceTableHead{
    white-space: wrap;
}


@media screen and (max-width: 720px) {
    .inv_con .text_boxes{
        width: 46%;
        /* border: 1px solid red; */
    }

    .inv_con{
        font-size: 12px;
    }
}