.switch {
    position: relative;
    display: inline-block;
    width: 53px !important;
    height: 28px !important;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px !important;
    width: 22px !important;
    left: 3px !important;
    bottom: 3px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #105293;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .prod_desc{
    font-size: 12px;
    width: 57%;
    font-weight: 500;
    margin-left: 3px;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
  }


  .searchDiv{
    border: 1px solid #105293;
  }

  .searchDiv select {
    font-size: 13px;
    font-weight: 600;
    background: #105293;
    color: white;
  }

  .searchDiv input{
    border: none;
    outline: none;
    font-size: 12px;
    
  }

  .btnvar{
    height: 31px !important;
  }

  .filterselection{
    transition: opacity 1s ease, transform 1s ease;
    border-radius: 10px;
    width: 100%;
    background-color: white;
    min-height: 250px;
    position: absolute;
    z-index: 10;
  }

  /* React Tabs  */

.catelog .custom-tab-list {
  list-style: none;
  display: flex;
  background-color: transparent;
}

.catelog .custom-tab-list .custom-tab {
  cursor: pointer;
  height: 0;
  width: 33.33%;
  border-bottom: 35px solid #CCCCCC;
  box-sizing: border-box;
  background: white;
  outline: none;
  line-height: 2;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: #000;

  &:hover {
    background-color: #d0d0d0;
  }

  &.react-tabs__tab--selected {
    color: #105293;
    transition: 300ms;
    border-radius: 20px;
    border-bottom: 3px solid
  }
}

.inputWidth{
  width: 75%;
}

.modalTrigerButton {
  background: #d3d3d3;
  border-radius: 14px;
  color: #000;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  padding: 6px;
}
.functionalButtons {
  background: #d3d3d3;
  border-radius: 12px;
  color: #000;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  height: 25px;
  padding-top: 3px;
  text-align: center;
  width: 70px;
}

.syncContainers{
  display: flex;
  align-items: center;
  gap: 20px;
}
.syncContainers input{
  width: 20px;
  height: 20px;
}
.syncContainers label{
  font-size: 17px;
  color: black;
}

@media screen and (max-width: 450px) {
  .inputWidth{
    width: 100%;
  }

  .inputWidth::placeholder{
    font-size: 12px;
  }
  

  .catelog .custom-tab-list .custom-tab{
    font-size: 10px;
  }

  .filterWidth{
    width: 55% !important;
  }
  .sortWidth{
    width: 40% !important;
  }

  .prod_desc .genericlabel{
    font-size: 10px;
  }

  .badge{
    font-size: 55% !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 42px !important;
    height: 21px !important;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(17px) !important;
    -ms-transform: translateX(17px) !important;
    transform: translateX(17px) !important;
  }

  .slider:before{
    bottom: 0px !important;
  }
}


@media screen and (max-width: 330px){
  .catelog .custom-tab-list .custom-tab{
    font-size: 9px;
  }
}
