table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  table td,
  table th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
    /* width: 100px!important;
    max-width: 100px; */
    text-align: center;
  }
  
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  table tr:hover {
    background-color: #ddd;
  }
  
  thead{
    white-space:nowrap;
  }

  table th,
  tfoot td {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    text-align: center;
    background: #001ad5;
    color: white;
  }
  .page_link_item {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dddfeb;
  }
  