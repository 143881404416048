.tab_buttons {
  background-color: #0094ff;
  /* border: none; */
  outline: none;
  margin: 2px;
  padding: 6px;




}


.tabs {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
}

.tab {
  height: 0;
  width: 33.33%;
  border-bottom: 35px solid #CCCCCC;
  border-right: unset;
  border-top-left-radius: none;
  box-sizing: border-box;
  /* display: block; */
  background: white;
  font-size: 14px;
  outline: none;
  line-height: 31px;
  border-top: none;
  border-left: none;
  font-size: 13px;
  /* border-top-left-radius: 6px; */
  font-weight: 600;
}



.tab:not(:first-child) {
  /* margin-left: -10px; */
  z-index: 0;
}

.tab .label {
  padding: 8px;
  text-align: center;
  /* color: #444444; */
  font-weight: 600;

}

.active {
  /* border-bottom: 35px solid #105293; */
  color: #105293;
  transition: 300ms;
  border-radius: 20px;
  border-bottom: 3px solid #105293;
}

.active .label {
  color: #ffffff;
}


/*  */


/* Add these styles to your stylesheet */

/* Style for the main container */
.taxlistTemplateDesc {
  /* border: 1px solid #ccc; */
  padding: 15px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 17px 4px rgb(0 0 0 / 10%);

}

/* Style for the Category section */

.taxlistTemplateDesc>div:last-child {
  display: flex;
  align-items: center;
  gap: 10px;
}

.taxlistTemplateDesc button {
  padding: 8px 15px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.taxlistTemplateDesc {
  padding: 15px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.taxlistTemplateDesc img {
  width: 50%; 
  height: auto; 
}

.taxlistTemplateDesc .form-check {
  margin-left: auto;
}

.taxlistTemplateDesc .badge.btn {
  cursor: not-allowed;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
