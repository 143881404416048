.sellerTemplatemain {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* height: 150px; */
    background-color: white;
    margin: 14px 0px;
    display: none;
    padding: 7px;
    border-radius: 12px;
    border: 1px solid black;
}

.sellerTemplateImage {
    /* border: 1px solid red; */
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.sellerTemplateImage img {
    width: 100%;
    height: 100%;
    /* border: 1px solid rgb(27, 2, 255); */
    object-fit: contain;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
}

.sellerTemplateDesc {
    width: 90%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 5px;
    gap: 6px;
    /* border: 1px solid rgb(4, 245, 104); */
}

.sellerTemplateDesc_mail{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.sellerTemplateDesc_mail> p{
    display: inline;
    width: 80%;
}

.sellerTemplateDesc button {
    background-color: #001AD5;
    border: none;
    outline: none;
    width: 87.94px;
    height: 27.84px;
    line-height: 14px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    padding: 5px;

}

.sellerTemplateDesc p:nth-child(3) {
    color: black;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 3px
}

.sellerTemplateDesc p:nth-child(1) {
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

}

.sellerTemplateButtons {
    padding-top: 20px;
}

.success{
    background-color: blue !important;
    font-size: 14px !important;
    font-weight: bold !important;
}
.danger{
    background-color: red !important;
    font-size: 14px !important;
    font-weight: bold !important;
}

@media screen and (max-width: 786px) {
    .sellerTemplateMain {
        display: block;
    }

    .sellerTemplateDesc p {
        display: inline-block;
    }
}

@media screen and (max-width: 375px) {

    .sellerTemplateDesc {
        gap: 5px;
    }

    .sellerTemplateDesc p {
        font-size: 12px !important;
    }

    .sellerTemplateImage img {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }

    .sellerTemplateDesc p:nth-child(3) {
        padding-bottom: 0px
    }

    /* .sellerTemplateDesc button {
        width: 80.94px;
        height: 27.84px;
    } */
}

@media screen and (max-width: 375px) {
    .sellerTemplatemain {
        padding: 10px;
    }
}